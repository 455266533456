import React from "react";
import Loader from "react-loader-spinner";
import { APP_THEME } from "../const/theme";

export const LoaderPlaceHolder = () => {
  return (
    <Loader
      type="MutatingDots"
      color={"#5546ff"}
      secondaryColor={"#000000"}
      height={160}
      width={160}
    />
  );
};
