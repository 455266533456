import React, { createContext, useContext, useState } from "react";

type INIT_STATE_TYPE = {
  won: boolean;
  checked: boolean;
  loading: boolean;

  _won: (status: boolean) => void;
  _checked: (status: boolean) => void;
  _loading: (status: boolean) => void;
};

const INIT_STATE = {};

export const StateContext = createContext<INIT_STATE_TYPE>(null!);

const StateLogic = (props: React.PropsWithChildren<{}>) => {
  const [won, _won] = useState(false);
  const [checked, _checked] = useState(false);
  const [loading, _loading] = useState(false);
  let contextValue = {
    checked,
    _checked,
    won,
    _won,

    loading,
    _loading,
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
};

export default StateLogic;

export const useAppState = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
};
