import { useConnect } from "@stacks/connect-react";
import React, { useEffect, useState } from "react";
import { Suspense, useRef } from "react";
import ReactAudioPlayer from "react-audio-player";
import styled, { keyframes } from "styled-components";
import { opacity } from "styled-system";
import * as three from "three";
import UserWindow, { isMobile } from "../utils";
import { LoaderPlaceHolder } from "./Loader";
import { useAppState } from "./state";

import {
  ColumnFlex,
  Button,
  TriText,
  LabelHeading,
  TextHeading,
  RowFlex,
  SubHeading,
  TriHeading,
  SubText,
  Text,
} from "./styled";

const OverlayBlur = styled.div`
  /* background: linear-gradient(top, rgba(85,70,255,0.70), rgba(254,0,114,0.70)) */
  background-image: linear-gradient(
    top,
    rgba(85, 70, 255, 0.7),
    rgba(254, 0, 114, 0.7)
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(85, 70, 255, 0.7),
    rgba(254, 0, 114, 0.7)
  );
`;

const VideoCon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const VideoStyled = styled.video`
  /* Make video to at least 100% wide and tall */
  min-width: 90%;
  min-height: 90%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);

  @media (min-width: 800px) {
    transform: translate(-50%, -50%);
  }
`;

const delay = 1.5;

type ActionProps = {};
const ActionView = (props: ActionProps) => {
  const [hasActionMounted, _hasActionMounted] = useState(false);
  const { checked, won } = useAppState();
  useEffect(() => {
    let timer1 = setTimeout(() => _hasActionMounted(true), delay * 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, []);
  console.log("what");
  if (!hasActionMounted) {
    return null;
  }
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "relative",
      }}
    >
      {won ? <WinView /> : <LoseView />}
    </div>
  );
};

type ActionVideoProps = {};

const DivTextAnimateOpacity = styled.div`
  transition: all ease 0.2s;
`;

const LoseView = (props: ActionVideoProps) => {
  const videoRef = React.createRef<HTMLVideoElement>();
  const [show, _show] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("ended", (event) => {
        _show(true);
      });
    }
  }, []);

  return (
    <>
      <DivTextAnimateOpacity
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          top: 0,
          zIndex: 30,
          opacity: show ? 1 : 0,
        }}
      >
        <ColumnFlex height={"100%"} justifyContent="center" alignItems="center">
          <TriHeading
            fontWeight={800}
            textAlign={"center"}
            color={"white"}
            marginTop="10px"
            style={{
              marginTop: "20px",
              width: "80%",
            }}
          >
            You didn't win,
          </TriHeading>
          <TriHeading
            fontWeight={800}
            textAlign={"center"}
            color={"white"}
            marginTop="10px"
            style={{
              marginTop: "20px",
              width: "80%",
            }}
          >
            but you found the real treasure!
          </TriHeading>
          <Text
            marginTop={"30px"}
            textAlign={"center"}
            color={"white"}
            fontWeight={500}
            style={{
              maxWidth: "800px",
              width: "80%",
            }}
          >
            Thanks for playing! Your NFT didn't unlock a special treasure chest,
            but it's yours to keep. With your Hiro web wallet you can now easily
            enter a world of Stacks apps secured by Bitcoin.
          </Text>
          <RowFlex>
            <Text
              marginTop={"0px"}
              textAlign={"center"}
              color={"white"}
              fontWeight={500}
            >
              Welcome to the
            </Text>
            <a
              style={{
                textDecoration: "none",
                borderBottom: "1px solid rgb(209, 209, 209)",
                margin: "0 5px",
              }}
              href="https://cointelegraph.com/news/stacks-ecosystem-becomes-1-web3-project-on-bitcoin"
              target="_blank"
            >
              <Text fontWeight={500} color="white">
                #1 Web 3 project on Bitcoin!
              </Text>
            </a>
          </RowFlex>
          <Button
            textAlign={"center"}
            onClick={() => (document.location.href = "https://www.stacks.co/")}
            backgroundColor={"#5546ff"}
            color={"white"}
            width={"240px"}
            height="60px"
            borderRadius={"7px"}
            marginTop="40px"
            padding={"0px 0"}
          >
            <Text fontFamily={"OpenSauceOneRegular"}>Explore Stacks</Text>
          </Button>
          <RowFlex margin={"80px 0 0 0"}>
            <a
              style={{
                textDecoration: "none",
                borderBottom: "1px solid rgb(209, 209, 209)",
                margin: "0 5px",
              }}
              href="https://twitter.com/stacks"
              target="_blank"
            >
              <TriText color="white">Twitter</TriText>
            </a>
            <a
              style={{
                borderBottom: "1px solid rgb(209, 209, 209)",
                textDecoration: "none",
                margin: "0 5px",
              }}
              href="https://discord.com/invite/5DJaBrf"
              target="_blank"
            >
              <TriText color="white">Discord</TriText>
            </a>
            <a
              style={{
                borderBottom: "1px solid rgb(209, 209, 209)",
                textDecoration: "none",
                margin: "0 5px",
              }}
              href="https://github.com/stacks-network"
              target="_blank"
            >
              <TriText color="white">Github</TriText>
            </a>
          </RowFlex>
        </ColumnFlex>
      </DivTextAnimateOpacity>

      <VideoCon>
        <VideoStyled autoPlay muted ref={videoRef}>
          <source src="/loseVideo.mp4" type="video/mp4" />
        </VideoStyled>
      </VideoCon>
    </>
  );
};
const WinView = (props: ActionVideoProps) => {
  console.log("WinView.", props);
  const videoRef = React.createRef<HTMLVideoElement>();
  const screenInfo = UserWindow();

  const [show, _show] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => _show(true), 4000);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("ended", (event) => {
        console.log(
          "Video stopped either because 1) it was over, " +
            "or 2) no further data is available."
        );
        restartVideo();
      });
    }
  }, []);

  const restartVideo = () => {
    console.log("try this maybe");
    if (videoRef.current) {
      console.log("did this run");
      videoRef.current.currentTime = 7;
      videoRef.current.play();
    }
  };
  return (
    <>
      <DivTextAnimateOpacity
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          top: 0,
          zIndex: 30,
          opacity: show ? 1 : 0,
        }}
      >
        <ColumnFlex height={"100%"} justifyContent="center" alignItems="center">
          <TriHeading
            fontWeight={800}
            textAlign={"center"}
            color={"white"}
            marginTop="10px"
            style={{
              marginTop: "20px",
            }}
          >
            You won!
          </TriHeading>
          <Text
            marginTop={"30px"}
            textAlign={"center"}
            color={"white"}
            fontWeight={500}
            style={{
              maxWidth: "800px",
              width: "80%",
            }}
          >
            You won! The Okcoin team will be delivering your prize to your
            exchange wallet in the next 48-72 hours! In the meantime, use your
            new Hiro Wallet to explore DeFi, NFTs and more across the #1 Web 3
            project on Bitcoin.
          </Text>

          <Button
            textAlign={"center"}
            backgroundColor={"#5546ff"}
            onClick={() => (document.location.href = "https://www.stacks.co/")}
            color={"white"}
            width={"240px"}
            height="60px"
            borderRadius={"7px"}
            marginTop="40px"
            padding={"0px 0"}
          >
            <Text fontFamily={"OpenSauceOneRegular"}>Explore Stacks</Text>
          </Button>
          <RowFlex margin={"80px 0 0 0"}>
            <a
              style={{
                textDecoration: "none",
                borderBottom: "1px solid rgb(209, 209, 209)",
                margin: "0 5px",
              }}
              href="https://twitter.com/stacks"
              target="_blank"
            >
              <TriText color="white">Twitter</TriText>
            </a>
            <a
              style={{
                borderBottom: "1px solid rgb(209, 209, 209)",
                textDecoration: "none",
                margin: "0 5px",
              }}
              href="https://discord.com/invite/5DJaBrf"
              target="_blank"
            >
              <TriText color="white">Discord</TriText>
            </a>
            <a
              style={{
                borderBottom: "1px solid rgb(209, 209, 209)",
                textDecoration: "none",
                margin: "0 5px",
              }}
              href="https://github.com/stacks-network"
              target="_blank"
            >
              <TriText color="white">Github</TriText>
            </a>
          </RowFlex>
        </ColumnFlex>
      </DivTextAnimateOpacity>

      <VideoCon>
        <VideoStyled
          // style={{
          //   height: "100vh",
          //   width: "100vw",
          // }}
          autoPlay
          muted
          ref={videoRef}
        >
          <source src="/winVideo2.mp4" type="video/mp4" />
        </VideoStyled>
      </VideoCon>
    </>
  );
};

const breatheAnimation = keyframes`
  to { transform: rotate(360deg); }
`;

const CircleThing = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #5546ff;
  border-radius: 50%;
  border-top-color: #5546ff;
  animation:  1s ease-in-out infinite;
  animation-name: ${breatheAnimation}
  -webkit-animation: spin 1s ease-in-out infinite;
`;
const BlackDivAnimate = styled.div`
  position: fixed;
  transition: all ease 0.3s;

  width: 100vw;
  height: 100vh;
  background-color: black;
`;
const Find = () => {
  const { doOpenAuth } = useConnect();
  const { checked, won, _loading, loading } = useAppState();

  const handleAuth = () => {
    _loading(true);
    doOpenAuth();
  };
  return (
    <>
      <BlackDivAnimate
        style={{
          opacity: checked ? "1" : "0",
          zIndex: checked ? 100 : 1,
        }}
      >
        <ActionView />
      </BlackDivAnimate>

      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "relative",
        }}
      >
        <VideoCon>
          <VideoStyled
            // style={{
            //   height: "100vh",
            //   width: "100vw",
            // }} `
            autoPlay
            loop
            muted
          >
            <source src="/idelVideo.mp4" type="video/mp4" />
          </VideoStyled>
        </VideoCon>
        <div
          style={{
            position: "absolute",
            right: 0,
            left: 0,
            margin: "auto",
            bottom: "2%",
            zIndex: 45,

            width: "100vw",
          }}
        >
          <RowFlex
            width={"100%"}
            justifyItems="center"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <TriText
              style={{
                maxWidth: "600px",
                width: "85%",
              }}
              color={"black"}
              textAlign="center"
            >
              Your key NFT is linked to your wallet address, you cannot purchase
              a winning NFT from another person, please watch out for scams
            </TriText>
          </RowFlex>
        </div>

        <OverlayBlur
          style={{
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            zIndex: 10,
          }}
        />

        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            top: 0,
            zIndex: 30,
          }}
        >
          <ColumnFlex
            height={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <TriHeading
              fontWeight={800}
              textAlign={"center"}
              color={"white"}
              marginTop="10px"
              style={{
                marginTop: "20px",
                width: "90%",
              }}
            >
              Does your key unlock Stacks treasure?
            </TriHeading>
            <Text
              marginTop={"0px"}
              textAlign={"center"}
              color={"white"}
              fontWeight={500}
              style={{
                maxWidth: "600px",
                width: "85%",
              }}
            >
              Click the button below to connect your Hiro wallet and see if
              you're a winner
            </Text>

            {isMobile ? (
              <ColumnFlex alignItems={"center"} marginTop={"20px"}>
                <Text textAlign={"center"} color={"white"} fontWeight={500}>
                  You need to have the Hiro Wallet for Web installed and logged
                  into the address you used to register on Okcoin's site
                </Text>
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  href="https://chrome.google.com/webstore/detail/hiro-wallet/ldinpeekobnhjjdofggfgjlcehhmanlj"
                  target="_blank"
                >
                  <Text
                    fontWeight={500}
                    style={{
                      borderBottom: "1px solid rgb(209, 209, 209)",
                    }}
                    textAlign="center"
                    color="white"
                  >
                    Like Hiro for Web Wallet
                  </Text>
                </a>
              </ColumnFlex>
            ) : (
              <>
                {loading ? (
                  <LoaderPlaceHolder />
                ) : (
                  <Button
                    onClick={() => handleAuth()}
                    textAlign={"center"}
                    backgroundColor={"#5546ff"}
                    color={"white"}
                    width={"160px"}
                    height="60px"
                    borderRadius={"7px"}
                    marginTop="35px"
                    padding={"5px 0"}
                  >
                    <Text fontFamily={"OpenSauceOneRegular"}>Unlock</Text>
                  </Button>
                )}
              </>
            )}
          </ColumnFlex>
        </div>
      </div>
    </>
  );
};

export default Find;
