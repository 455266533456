import { ColumnFlex } from "./styled";

import { AppConfig, UserSession } from "@stacks/connect";
import { Connect } from "@stacks/connect-react";

import { StacksLose, StackWin } from "./LandingViews";
import { APP_COLORS } from "../const/colors";
import { useRef, useState } from "react";

import Find from "./Find";
import { GraphQLClient, gql } from "graphql-request";
import { useAppState } from "./state";

const appConfig = new AppConfig(["store_write", "publish_data"]);
const userSession = new UserSession({ appConfig });

const Landing = () => {
  const { _checked, _won, _loading } = useAppState();

  const handleCheckAddy = async (senderAddress: string) => {
    try {
      const endpoint = "https://protected-oasis-02113.herokuapp.com/graphql";
      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          authorization: "",
        },
      });

      const query = gql`
        {
          checkAddy(txId: "${senderAddress}") 
        }
      `;

      const data = await graphQLClient.request(query);
      console.log("data", data.checkAddy);

      if (data.checkAddy) {
        if (data.checkAddy === "Lost") {
          _won(false);
        } else if (data.checkAddy === "Won") {
          _won(true);
        }
      }
      _checked(true);
      _loading(false);
    } catch (err) {
      console.log("err", err);
      _loading(false);
    }
  };

  const AUTH_OPTIONS = {
    appDetails: {
      name: "Stacks Foundation",
      icon: "https://thumb.tildacdn.com/tild3931-3038-4632-b063-313631343738/-/resize/110x/-/format/webp/Foundation_Logo_Blac.png",
    },
    redirectTo: "/",
    onFinish: async () => {
      try {
        let userData = userSession.loadUserData();

        const senderAddress = userData.profile.stxAddress.mainnet;

        handleCheckAddy(senderAddress);
      } catch (err) {
        _loading(false);
        console.log("noooo", err);
      }
    },
    userSession: userSession,
  };

  return (
    <Connect authOptions={AUTH_OPTIONS}>
      <Find />
    </Connect>
  );
};

export default Landing;
