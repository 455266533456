import React, { useEffect } from "react";
import { Suspense, useRef } from "react";
import { Stats, OrbitControls } from "@react-three/drei";
import * as three from "three";

import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";
import { ToastContainer, toast } from "react-toastify";

import "../css/App.css";

import { APP_COLORS } from "../const/colors";
import stars from "./stars";
import {
  ColumnFlex,
  ContentBodyCon,
  Button,
  Heading,
  SubHeading,
  TriHeading,
  Text,
  SubText,
  TriText,
  LabelHeading,
  TextHeading,
} from "./styled";
import { Canvas, useFrame } from "@react-three/fiber";

import { APP_THEME } from "../const/theme";

import { backgroundColor, borderRadius } from "styled-system";

import StateLogic from "./state";
import Landing from "./Landing";

import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { Html, useGLTF } from "@react-three/drei";

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    height: 100%;
  }
  body {
    display: block;
    height: 100%;
    overflow-x: hidden;

    margin: 0;
    min-height: 100%;
   
    font-family: Arial,sans-serif;
   
  }
  #root {
    display: block;
    height: 100%;
  


    margin: 0;
    min-height: 100%;
   
    font-family: Arial,sans-serif;
  }
`;

const INIT_CUBE_POSITION = [];

const StyledToast = styled(ToastContainer)`
  &&&.wild {
    width: 520px;
  }
  &&&.nild {
    max-height; 800px;
  }
`;

function App() {
  const thanksRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <ThemeProvider theme={APP_THEME}>
        <StateLogic>
          <GlobalStyle />
          <Landing />
        </StateLogic>
      </ThemeProvider>
    </>
  );
}

export default App;
